import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21c5f44a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wizard-step-editor",
  "data-testid": "wizard-step-editor"
}
const _hoisted_2 = { class: "wizard-step-editor__col" }
const _hoisted_3 = { class: "wizard-step-editor__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPencil = _resolveComponent("IconPencil")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_AppButton, {
      class: "wizard-step-editor__button",
      type: "bordered",
      label: _ctx.$t('labels.common.edit'),
      onClick: _ctx.handleButtonClick
    }, {
      prepend: _withCtx(() => [
        _createVNode(_component_IconPencil)
      ]),
      _: 1
    }, 8, ["label", "onClick"])
  ]))
}