<script setup lang="ts">
import { computed } from 'vue';
import { SidebarAction, SidebarActionList } from '@/shared/types/components';
import { symRoundedMoreVert } from '@quasar/extras/material-symbols-rounded';
import AppSidebarMenuItem from '../AppSidebarMenuItem/AppSidebarMenuItem.vue';
import AppTooltip from '../AppTooltip/AppTooltip.vue';
import AppButtonDropdown from '../AppButtonDropdown/AppButtonDropdown.vue';
import AppButton from '../AppButton/AppButton.vue';

interface Props {
  list: SidebarActionList;
}

const props = defineProps<Props>();

const filteredList = computed<SidebarAction[]>(() => {
  return (
    props.list.dropdownList?.filter((item: SidebarAction) => !item.isHidden) ??
    []
  );
});
</script>

<template>
  <AppButton
    v-for="item in list.mainActionList"
    :key="item.label"
    :disable="item.disabled"
    class="app-sidebar-action__button"
    type="icon"
    @click="item.action"
  >
    <component :is="item.icon" width="16" height="16" />

    <AppTooltip
      anchor="top middle"
      self="bottom middle"
      :offset="[5, 5]"
      max-width="220px"
    >
      {{ item.tooltip }}
    </AppTooltip>
  </AppButton>

  <AppButtonDropdown
    v-if="filteredList.length"
    :dropdown-icon="symRoundedMoreVert"
    type="transparent"
    auto-close
    padding="0px"
    no-background
  >
    <AppSidebarMenuItem
      v-for="item in filteredList"
      :key="item.label"
      :label="item.label"
      :description="item.description"
      :disabled="item.disabled"
      class="app-sidebar-action__button"
      @click="item.action"
    >
      <template #icon>
        <component :is="item.icon" width="16" height="16" />
      </template>
      <AppTooltip
        v-if="item.disabled"
        anchor="top middle"
        self="bottom middle"
        :offset="[5, 5]"
        max-width="220px"
      >
        {{ item.tooltip }}
      </AppTooltip>
    </AppSidebarMenuItem>
  </AppButtonDropdown>
</template>

<style scoped lang="scss">
.app-sidebar-action__button {
  padding: 8px;
}
</style>
