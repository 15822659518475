<script setup lang="ts">
import AppButton from '@/components/app/AppButton/AppButton.vue';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import { useI18n } from 'vue-i18n';

const showModal = defineModel<boolean>({
  required: true,
});

const { t } = useI18n();

interface Emits {
  (e: 'discard'): void;
}

const emit = defineEmits<Emits>();

function handleDiscardClick(): void {
  emit('discard');
  showModal.value = false;
}
</script>

<template>
  <AppModal v-model="showModal" no-route-dismiss persistent>
    <template #header>{{ t('modal.header.saveChanges') }}</template>
    <template #body>{{ t('modal.body.saveChanges') }}</template>
    <template #footer="{ dismiss }">
      <AppButton
        type="empty"
        data-testid="cancel-modal"
        :label="t('labels.common.cancel')"
        @click="dismiss"
      />
      <AppButton
        type="filled"
        data-testid="dont-save-modal"
        :label="t('labels.common.discard')"
        @click="handleDiscardClick"
      />
    </template>
  </AppModal>
</template>
