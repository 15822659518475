<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { formatDateToString } from '@/shared/helpers/formatDates/formatDates';
import AppMessage from '@/components/app/AppMessage/AppMessage.vue';
import useProfileStore from '@/store/profile/useProfileStore';
import AppInformation from '@/components/app/AppInformation/AppInformation.vue';

interface Props {
  date: number;
  sidebar?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  sidebar: false,
});

const profileStore = useProfileStore();

const { t } = useI18n();
</script>

<template>
  <div
    :class="[
      'app-results-release',
      { 'app-results-release--sidebar': props.sidebar },
    ]"
  >
    <AppMessage type="empty" class="app-results-release__content">
      <span
        class="app-results-release__content-message app-results-release__content-message--title"
      >
        {{ t('timezones.resultsRelease.label') }}
      </span>
      <span class="app-results-release__content-message">
        {{
          formatDateToString(
            new Date(props.date),
            t('common.format.date.maskDateAndTime'),
            profileStore.userProfileSite?.site.timezone.ianaTimezone || '',
          )
        }}
      </span>

      <AppInformation>
        <span> {{ t('timezones.resultsRelease.tooltip') }}</span>
      </AppInformation>
    </AppMessage>
  </div>
</template>

<style scoped lang="scss">
.app-results-release {
  flex-grow: 1;
  display: flex;
}

.app-results-release__content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.app-results-release__content-message {
  color: $gray-800;
  font-size: 14px;
  line-height: 140%;
}

.app-results-release__content-message--title {
  font-size: 14px;
  font-weight: 700;
}

.app-results-release :deep(.app-message.app-message--empty) {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-results-release .app-message.app-message--empty {
  background: $blue-50;
  width: 336px;
}

.app-results-release.app-results-release--sidebar
  :deep(.app-message.app-message--empty) {
  background-color: $gray-25;
  flex-grow: 1;
}
</style>
