<template>
  <AppField :error="error">
    <div class="app-button-toggle">
      <q-btn-toggle
        v-model="model"
        :options="options"
        :ripple="false"
        :toggle-color="null"
        :text-color="null"
        :toggle-text-color="null"
        :class="[
          { 'app-button-toggle__toggle--error': hasError },
          'app-button-toggle__toggle',
        ]"
        :disable="disabled"
        unelevated
        data-testid="app-button-toggle"
      >
        <template
          v-for="option in options.filter((opt) => !!opt.slot)"
          :key="option.value"
          #[option.slot]
        >
          <slot :name="option.slot">
            <AppTooltip
              v-if="option.tooltip"
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              {{ option.tooltip }}
            </AppTooltip>
          </slot>
        </template>
      </q-btn-toggle>
    </div>
    <template #error>
      <AppErrorMessage :error="error" />
    </template>
    <slot name="hintSpace" class="hint"></slot>
  </AppField>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AppButtonToggleOption } from '@/shared/types/generic';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';
import AppField from '../AppField/AppField.vue';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

export default defineComponent({
  name: 'AppButtonToggle',

  components: { AppErrorMessage, AppField, AppTooltip },

  props: {
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    options: {
      type: Array as PropType<AppButtonToggleOption[]>,
      required: true,
    },
    error: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(newValue: string | number) {
        this.$emit('update:modelValue', newValue);
      },
    },

    hasError() {
      return !!this.error && this.error.length > 0;
    },
  },
});
</script>

<style scoped lang="scss">
.app-button-toggle {
  position: relative;
}

.app-button-toggle__toggle {
  background-color: $white;
  min-height: 48px;
  overflow: hidden;
  width: fit-content;
}

.app-button-toggle__toggle--error :deep(.q-btn):first-child {
  border-top-color: $red-500;
  border-bottom-color: $red-500;
  border-left-color: $red-500;
}

.app-button-toggle__toggle--error :deep(.q-btn):last-child {
  border-top-color: $red-500;
  border-bottom-color: $red-500;
  border-right-color: $red-500;
}

.app-button-toggle__toggle :deep(.q-btn) {
  min-height: unset;
  border-radius: 0;
  text-transform: unset;
  padding: 0 16px;
  border: 1px solid $gray-400;
}

.app-button-toggle__toggle :deep(.q-btn):first-child {
  border-radius: 4px 0 0 4px;
}

.app-button-toggle__toggle :deep(.q-btn):last-child {
  border-radius: 0 4px 4px 0;
}

.app-button-toggle__toggle :deep(.q-hoverable:hover > .q-focus-helper) {
  background: none;
}

.app-button-toggle__toggle :deep(.q-btn) span {
  color: $gray-800;
}

.app-button-toggle__toggle :deep(.q-btn.bg-null) {
  background-color: $blue-50;
  border: 1px solid $blue-500;
}

.app-button-toggle__toggle :deep(.q-btn.bg-null) .q-btn__content > span {
  color: $blue-500;
  font-weight: 700;
}

.hint {
  min-height: 300px;
}
</style>
