<script setup lang="ts">
import AppButton from '@/components/app/AppButton/AppButton.vue';
import { AppButtonType } from '@/shared/types/components';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { WHITE, BLUE_500 } from '@/shared/constants/colors';
import AppTooltip from '@/components/app/AppTooltip/AppTooltip.vue';

const { t } = useI18n();

interface Props {
  isLastStep: boolean;
  isFirstStep: boolean;
}

interface Emits {
  (e: 'continue'): void;
  (e: 'previous'): void;
  (e: 'cancel'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const leftButtonLabel = computed<string>(() => {
  return props.isLastStep
    ? t('labels.common.previous')
    : t('labels.common.cancel');
});

const leftButtonType = computed<AppButtonType>(() => {
  return props.isLastStep ? 'bordered' : 'empty';
});

function executeLeftButtonAction(): void {
  if (props.isLastStep) {
    emit('previous');
  } else {
    emit('cancel');
  }
}
</script>

<template>
  <div class="app-wizard-footer">
    <AppButton
      :type="leftButtonType"
      size="M"
      :label="leftButtonLabel"
      @click="executeLeftButtonAction"
    >
      <template #prepend>
        <IconArrowDown
          v-if="props.isLastStep"
          width="16"
          height="16"
          :color="BLUE_500"
          class="app-wizard-footer__arrow"
        />
      </template>
      <AppTooltip
        v-if="!props.isLastStep"
        anchor="top middle"
        self="bottom middle"
        :offset="[5, 5]"
      >
        {{ $t('games.challengeWizard.cancel') }}
      </AppTooltip>
    </AppButton>

    <div v-if="!props.isLastStep" class="app-wizard-footer__flow-buttons">
      <AppButton
        v-if="!props.isFirstStep"
        type="bordered"
        size="M"
        :label="t('labels.common.previous')"
        @click="emit('previous')"
      >
        <template #prepend>
          <IconArrowDown
            width="16"
            height="16"
            :color="BLUE_500"
            class="app-wizard-footer__arrow"
          />
        </template>
      </AppButton>
      <AppButton
        type="vaibe"
        size="M"
        :label="t('labels.common.continue')"
        @click="emit('continue')"
      >
        <IconArrowDown
          width="16"
          height="16"
          :color="WHITE"
          class="app-wizard-footer__arrow--front"
        />
      </AppButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-wizard-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-wizard-footer__arrow {
  transform: rotateZ(90deg);
}

.app-wizard-footer__arrow--front {
  transform: rotateZ(-90deg);
  margin-inline-start: 8px;
}

.app-wizard-footer__flow-buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
</style>
