<script setup lang="ts">
import { QInfiniteScroll } from 'quasar';
import { ref } from 'vue';

export interface AppInfiniteScrollRef {
  setIndex: (index: number) => void;
  poll: () => void;
  stop: () => void;
  resume: () => void;
  trigger: () => void;
}

const appInfiniteScroll = ref<QInfiniteScroll>();

function setIndex(index: number) {
  appInfiniteScroll.value?.setIndex(index);
}

function poll() {
  appInfiniteScroll.value?.poll();
}

function stop() {
  appInfiniteScroll.value?.stop();
}

function resume() {
  appInfiniteScroll.value?.resume();
}

function trigger() {
  appInfiniteScroll.value?.trigger();
}

defineExpose<AppInfiniteScrollRef>({
  setIndex,
  poll,
  stop,
  resume,
  trigger,
});
</script>

<template>
  <q-infinite-scroll
    ref="appInfiniteScroll"
    class="app-infinite-scroll"
    v-bind="$attrs"
  >
    <slot />
    <template #loading>
      <slot name="loading" />
    </template>
  </q-infinite-scroll>
</template>
