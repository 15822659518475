import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cc23219"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-list-item",
  "data-testid": "app-list-item"
}
const _hoisted_2 = { class: "app-list-item__container" }
const _hoisted_3 = {
  class: "app-list-item__title",
  "data-testid": "app-list-item-title"
}
const _hoisted_4 = {
  class: "app-list-item__subtitle",
  "data-testid": "app-list-item-subtitle"
}
const _hoisted_5 = {
  class: "app-list-item__description",
  "data-testid": "app-list-item-description"
}
const _hoisted_6 = {
  class: "app-list-item__count",
  "data-testid": "app-list-item-count"
}
const _hoisted_7 = {
  class: "app-list-item__actions",
  "data-testid": "app-list-item-actions"
}
const _hoisted_8 = { class: "app-list-item__actions__hint" }
const _hoisted_9 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTooltip = _resolveComponent("AppTooltip")!
  const _component_IconEdit = _resolveComponent("IconEdit")!
  const _component_IconDelete = _resolveComponent("IconDelete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "leading", {}, undefined, true),
      _createElementVNode("span", null, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, [
            _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
          ])
        ])
      ])
    ]),
    _createElementVNode("span", _hoisted_5, [
      _renderSlot(_ctx.$slots, "description", {}, undefined, true)
    ]),
    _createElementVNode("span", _hoisted_6, [
      _renderSlot(_ctx.$slots, "numberOfItens", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.editTooltip)
          ? (_openBlock(), _createBlock(_component_AppTooltip, {
              key: 0,
              anchor: "top middle",
              self: "bottom middle",
              offset: [10, 10],
              "max-width": "200px"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.editTooltip), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_IconEdit, {
          class: _normalizeClass([
            'app-list-item__actions-button',
            { opacity_disabled: _ctx.isEditDisabled },
          ]),
          width: "16px",
          height: "16px",
          "data-testid": "app-list-item-actions-edit-button",
          onClick: _ctx.handleEditClick
        }, null, 8, ["class", "onClick"])
      ]),
      _createElementVNode("div", {
        class: "app-list-item__actions__hint",
        "data-testid": "app-list-item-actions-hint-delete",
        title: _ctx.$t('labels.common.delete')
      }, [
        (_ctx.deleteTooltip)
          ? (_openBlock(), _createBlock(_component_AppTooltip, {
              key: 0,
              anchor: "top middle",
              self: "bottom middle",
              offset: [10, 10],
              "max-width": "200px"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.deleteTooltip), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_IconDelete, {
          class: _normalizeClass([
            'app-list-item__actions-button',
            { opacity_disabled: _ctx.isDeleteDisabled },
          ]),
          width: "16px",
          height: "16px",
          "data-testid": "app-list-item-actions-delete",
          onClick: _ctx.handleDeleteClick
        }, null, 8, ["class", "onClick"])
      ], 8, _hoisted_9)
    ])
  ]))
}