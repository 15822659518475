<script setup lang="ts">
import { useSlots } from 'vue';
import AppInformation from '../AppInformation/AppInformation.vue';

interface Props {
  title: string;
  tooltipText?: string;
  content?: string | number;
}

const props = defineProps<Props>();

const slots = useSlots();
</script>

<template>
  <div class="app-sidebar-row">
    <div class="app-sidebar-row__title">
      <span>{{ props.title }}</span>
      <AppInformation v-if="props.tooltipText" size="14px">
        {{ props.tooltipText }}
      </AppInformation>
      <slot name="tooltip" />
    </div>

    <div class="app-sidebar-row__content">
      <slot name="content" />
      <span v-if="!slots.content">{{ content }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-sidebar-row {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: row;
}
.app-sidebar-row__title {
  font-size: 14px;
  font-weight: 700;
  width: 152px;
  color: $gray-600;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-self: flex-start;
}

.app-sidebar-row__content {
  font-size: 14px;
  color: $gray-800;
  max-width: 340px;
  word-break: break-word;
}
</style>
