import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45443ead"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-breadcrumbs",
  "data-testid": "app-breadcrumbs"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matchedRoutes, (route, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (!_ctx.isLastRoute(route.name))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_router_link, {
                to: route.path
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, {
                    class: "app-breadcrumbs__button",
                    "data-testid": "app-breadcrumbs-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`nav.${String(route?.name)}`)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["to"]),
              _createVNode(_component_AppIcon, {
                class: "app-breadcrumbs__separator",
                name: "chevron_right",
                size: "18px"
              })
            ], 64))
          : (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.$t(`nav.${String(route?.name)}`)), 1))
      ], 64))
    }), 128))
  ]))
}