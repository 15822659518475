<template>
  <q-btn
    v-bind="$attrs"
    :class="[
      'app-button',
      `app-button--${type}`,
      `app-button--${size}`,
      { 'app-button--fit-content': fitContent },
      { 'app-button--reversed': isReversed },
    ]"
    data-testid="app-button"
    :ripple="false"
    :type="nativeType"
    unelevated
    no-caps
  >
    <slot name="prepend" />
    <slot />
    <BaseTooltip v-if="$props.tooltipText">
      {{ $props.tooltipText }}
    </BaseTooltip>
  </q-btn>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AppButtonType, AppButtonSize } from '@/shared/types/components';
import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';

export default defineComponent({
  name: 'AppButton',

  components: {
    BaseTooltip,
  },

  props: {
    type: {
      type: String as PropType<AppButtonType>,
      default: 'default',
    },
    nativeType: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<AppButtonSize>,
      default: 'S',
    },
    fitContent: Boolean,
    tooltipText: {
      type: String,
      default: '',
    },
  },

  computed: {
    isReversed(): boolean {
      return !!this.$slots.prepend;
    },
  },
});
</script>

<style scoped lang="scss">
.app-button:hover:not(
    .app-button--default,
    .app-button--vaibe,
    .app-button--flat,
    .app-button--icon
  ) {
  opacity: 0.7;
}

.app-button:disabled:not(
    .app-button--default,
    .app-button--flat,
    .app-button--icon
  ) {
  opacity: 0.5 !important;
  background-color: $gray-50;
  border: none;
}

.app-button:disabled:not(.app-button--default) :deep(.q-btn__content) {
  color: $gray-400;
}

.app-button:not(.app-button--default) :deep(.q-btn__content) {
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.app-button--filled :deep(.q-btn__content),
.app-button--vaibe :deep(.q-btn__content),
.app-button--danger :deep(.q-btn__content),
.app-button:disabled.app-button--add :deep(.q-btn__content) {
  color: $white;
}

.app-button--bordered :deep(.q-btn__content),
.app-button--empty :deep(.q-btn__content) {
  color: $blue-500;
}

.app-button--filled {
  background-color: $blue-500;
}

.app-button--vaibe:not(:hover) {
  background: $vaibe-gradient-dark;
  box-shadow: $special-shadow;
}

.app-button--danger {
  background-color: $red-500;
}

.app-button--bordered,
.app-button--empty {
  background-color: $white;
}

.app-button--bordered {
  border: 1px solid $blue-500;
}

.app-button--flat:hover:not(.app-button--flat:disabled) {
  background-color: $gray-50;
}

.app-button--vaibe:hover {
  outline-offset: -1px;
  background-color: $blue-700;
  box-shadow: $blue-400;
  outline: 1px solid $blue-800 !important;
}

.app-button :deep(.q-focus-helper) {
  display: none;
}

.app-button--S {
  border-radius: 20px;
  min-height: 32px;
  padding: 0 16px;
}

.app-button--M {
  min-height: 48px;
  padding: 0 24px;
}

.app-button--L {
  min-height: 56px;
  padding: 0 40px;
}

.app-button--XL {
  min-height: 64px;
  padding: 0 48px;
}

.app-button--M,
.app-button--L,
.app-button--XL {
  border-radius: 200px;
}

.app-button--icon {
  padding: 0px;
  color: $white;
  border-radius: 100px;
}

.app-button--add {
  background: $vaibe-gradient-dark;
  box-shadow: $special-shadow;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding: 9px;
  color: $white;
  border-radius: 100px;
}

.app-button--add:hover {
  background: $blue-500;
}

.app-button--add:focus-visible {
  background: $vaibe-gradient-dark;
  border: 1px solid $blue-300;
}

.app-button--add :deep(.q-icon) {
  height: 24px;
  width: 24px;
  display: flex;
}

.app-button :deep(.q-spinner .path) {
  color: $white;
}

.app-button--fit-content {
  padding: unset;
  min-height: unset;
  width: fit-content;
}

.app-button--reversed :deep(.q-btn__content) {
  flex-direction: row-reverse;
  gap: 8px;
}

.app-button--add:disabled:hover {
  background: $vaibe-gradient-dark;
}

.app-button:disabled.app-button--danger :deep(.q-btn__content) {
  color: $gray-500;
}
</style>
