<template>
  <div class="app-list-item" data-testid="app-list-item">
    <div class="app-list-item__container">
      <slot name="leading" />
      <span>
        <div class="app-list-item__title" data-testid="app-list-item-title">
          <slot name="title" />
        </div>
        <div>
          <span
            class="app-list-item__subtitle"
            data-testid="app-list-item-subtitle"
          >
            <slot name="subtitle" />
          </span>
        </div>
      </span>
    </div>
    <span
      class="app-list-item__description"
      data-testid="app-list-item-description"
    >
      <slot name="description" />
    </span>
    <span class="app-list-item__count" data-testid="app-list-item-count">
      <slot name="numberOfItens" />
    </span>
    <div class="app-list-item__actions" data-testid="app-list-item-actions">
      <div class="app-list-item__actions__hint">
        <AppTooltip
          v-if="editTooltip"
          anchor="top middle"
          self="bottom middle"
          :offset="[10, 10]"
          max-width="200px"
        >
          <span>{{ editTooltip }}</span>
        </AppTooltip>

        <IconEdit
          :class="[
            'app-list-item__actions-button',
            { opacity_disabled: isEditDisabled },
          ]"
          width="16px"
          height="16px"
          data-testid="app-list-item-actions-edit-button"
          @click="handleEditClick"
        />
      </div>
      <div
        class="app-list-item__actions__hint"
        data-testid="app-list-item-actions-hint-delete"
        :title="$t('labels.common.delete')"
      >
        <AppTooltip
          v-if="deleteTooltip"
          anchor="top middle"
          self="bottom middle"
          :offset="[10, 10]"
          max-width="200px"
        >
          <span>{{ deleteTooltip }}</span>
        </AppTooltip>
        <IconDelete
          :class="[
            'app-list-item__actions-button',
            { opacity_disabled: isDeleteDisabled },
          ]"
          width="16px"
          height="16px"
          data-testid="app-list-item-actions-delete"
          @click="handleDeleteClick"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppTooltip from '@/components/app/AppTooltip/AppTooltip.vue';
import IconEdit from '@/assets/icons/icon-edit.vue';
import IconDelete from '@/assets/icons/icon-delete.vue';

export default defineComponent({
  name: 'AppListItem',

  components: { AppTooltip, IconEdit, IconDelete },
  props: {
    editTooltip: {
      type: String,
      default: '',
    },
    deleteTooltip: {
      type: String,
      default: '',
    },
    isDeleteDisabled: {
      type: Boolean,
      default: false,
    },
    isEditDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['edit', 'delete'],

  methods: {
    handleEditClick(): void {
      if (!this.isEditDisabled) {
        this.$emit('edit');
      }
    },
    handleDeleteClick(): void {
      if (!this.isDeleteDisabled) {
        this.$emit('delete');
      }
    },
  },
});
</script>

<style scoped lang="scss">
.app-list-item {
  gap: 5%;
  display: flex;
  background: $white;
  padding: 16px 24px;
  border-radius: 16px;
  align-items: center;
}

.app-list-item + .app-list-item {
  margin-top: 16px;
}

.app-list-item__container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.app-list-item__title,
.app-list-item__description {
  font-size: 15px;
  overflow: hidden;
  font-weight: 400;
  line-height: 150%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.app-list-item__header {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.app-list-item__subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $gray-500;
}

.app-list-item__title {
  font-weight: 700;
  color: $gray-800;
}

.app-list-item__count {
  color: $blue-500;
  font-size: 15px;
  font-weight: 700;
  line-height: 150%;
}

.app-list-item__description {
  width: 30%;
  flex-grow: 1;
  color: $gray-500;
  min-height: 45px;
}

.app-list-item__actions {
  gap: 32px;
  display: flex;
  align-items: center;
}

.app-list-item__actions-button {
  cursor: pointer;
}

.opacity_disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.app-list-item__actions__hint {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
