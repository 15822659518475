import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72e4192c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-image-selector",
  "data-testid": "app-image-selector"
}
const _hoisted_2 = {
  class: "app-image-selector__button__overlay",
  "data-testid": "app-image-selector-button-overlay"
}
const _hoisted_3 = { class: "game-image-selector__icon" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  class: "app-image-selector__modal__header",
  "data-testid": "app-image-selector-modal-header"
}
const _hoisted_8 = {
  class: "app-image-selector__modal__header__title",
  "data-testid": "app-image-selector-modal-header-title"
}
const _hoisted_9 = { class: "app-image-selector__modal__body" }
const _hoisted_10 = ["onClick", "onKeydown"]
const _hoisted_11 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_IconEdit = _resolveComponent("IconEdit")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_IconSuccess = _resolveComponent("IconSuccess")!
  const _component_AppModal = _resolveComponent("AppModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'app-image-selector__button',
          { 'app-image-selector__button--disabled': _ctx.disabled },
        ]),
        "data-testid": "app-image-selector-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageSelectorClick && _ctx.handleImageSelectorClick(...args))),
        onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleImageSelectorClick && _ctx.handleImageSelectorClick(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.selectedImage)
            ? (_openBlock(), _createBlock(_component_AppIcon, {
                key: 0,
                class: "game-image-selector__icon__element game-image-selector__icon__image",
                name: 'img:' + _ctx.selectedImage,
                size: "140px",
                "data-testid": "app-image-selector-button-image"
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
          _createVNode(_component_AppButton, {
            class: "game-image-selector__icon__element game-image-selector__icon__element--edit",
            type: "add",
            rounded: "",
            disable: _ctx.disabled,
            onClick: _ctx.handleImageSelectorClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconEdit, {
                width: "16",
                height: "16",
                color: _ctx.WHITE
              }, null, 8, ["color"])
            ]),
            _: 1
          }, 8, ["disable", "onClick"])
        ])
      ], 34)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.errorMessage.name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_AppErrorMessage, {
                  error: _ctx.errorMessage.name
                }, null, 8, ["error"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.errorMessage.size)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_AppErrorMessage, {
                  error: _ctx.errorMessage.size
                }, null, 8, ["error"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_AppModal, {
      modelValue: _ctx.isImageSelectorModalShown,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isImageSelectorModalShown) = $event)),
      width: "unset",
      "max-height": "85%"
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.title), 1),
          (_ctx.showUpload)
            ? (_openBlock(), _createBlock(_component_AppButton, {
                key: 0,
                class: "app-image-selector__modal__upload",
                "data-testid": "app-image-selector-button-upload",
                onClick: _ctx.handleUploadImage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('store.uploadImage')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (image) => {
            return (_openBlock(), _createElementBlock("div", {
              key: image.reference,
              class: "app-image-selector__modal__body__image__container",
              "data-testid": "app-image-selector-modal-body",
              onClick: ($event: any) => (_ctx.handleImageClick(image)),
              onKeydown: ($event: any) => (_ctx.handleImageClick(image))
            }, [
              _createElementVNode("img", {
                class: _normalizeClass([
                'app-image-selector__modal__body__image',
                {
                  'app-image-selector__modal__body__image--selected':
                    _ctx.selectedImageName === image.name,
                },
              ]),
                src: image.url,
                alt: image.name,
                draggable: false
              }, null, 10, _hoisted_11),
              _withDirectives(_createVNode(_component_IconSuccess, { class: "app-image-selector__modal__body__image__checkbox" }, null, 512), [
                [_vShow, _ctx.selectedImageName === image.name]
              ])
            ], 40, _hoisted_10))
          }), 128))
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_AppButton, {
          type: "empty",
          "data-testid": "cancel-modal",
          label: _ctx.$t('labels.common.cancel'),
          onClick: _ctx.handleCancelClick
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_AppButton, {
          type: "filled",
          "data-testid": "save-modal",
          label: _ctx.$t('labels.common.save'),
          onClick: _ctx.handleSaveClick
        }, null, 8, ["label", "onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}