<template>
  <q-date
    v-model="model"
    class="app-date"
    data-testid="app-date"
    no-unset
    :locale="locale"
    v-bind="$props"
  >
    <slot />
  </q-date>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppDate',

  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    minimal: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      locale: {
        firstDayOfWeek: 0,
        daysShort: this.getWeekDaysShort([
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ]),
      },
    };
  },

  computed: {
    model: {
      get(): string | undefined {
        return this.modelValue;
      },

      set(newValue: string) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },

  methods: {
    getWeekDaysShort(weekDays: string[]): string[] {
      return weekDays.map((day) => this.$t(`common.weekDays.${day}`)[0]);
    },
  },
});
</script>

<style scoped lang="scss">
$date-item-height: 32px;
$date-item-width: 32px;

.app-date {
  border-radius: 8px;
  color: $gray-800;
  box-shadow: 0 2px 12px rgba(38, 37, 35, 0.12);
  position: relative;
}

.app-date.q-date {
  width: min-content;
  padding: 16px;
  box-sizing: content-box;
  background-color: $white;
}

.app-date :deep(.q-btn:hover > .q-focus-helper) {
  display: none;
}

.app-date :deep(.q-date__arrow) {
  height: $date-item-height;
  width: $date-item-width;
  justify-content: center;
}

.app-date :deep(.q-date__arrow:nth-child(3)) {
  margin-right: 8px;
}

.app-date :deep(.q-date__calendar-days > div),
.app-date :deep(.q-date__calendar-weekdays > div) {
  height: $date-item-height !important;
  width: $date-item-width !important;
}

.app-date :deep(.q-date__calendar-weekdays > div) {
  font-weight: 700;
  opacity: unset;
  font-size: 14px;
}

.app-date :deep(.q-date__content button.q-btn) {
  color: $gray-800 !important;
  border-radius: 0;
}

.app-date :deep(.q-date__calendar-item > button.q-btn--unelevated),
.app-date :deep(.q-date__years-item > button.q-btn--unelevated),
.app-date :deep(.q-date__months-item > button.q-btn--unelevated),
.app-date :deep(.q-date__calendar-days-container button.q-btn:hover),
.app-date :deep(.q-date__months button.q-btn:hover),
.app-date :deep(.q-date__years button.q-btn:hover) {
  background: $blue-50 !important;
  border-radius: 4px;
}

.app-date :deep(.q-date__months-item button),
.app-date :deep(.q-date__years-item button) {
  display: flex;
  align-items: center;
  text-transform: none;
  width: 100%;
  height: $date-item-height;
}

.app-date :deep(.q-date__calendar-days),
.app-date :deep(.q-date__calendar-weekdays),
.app-date :deep(.q-date__view.q-date__calendar) {
  display: flex;
  gap: 8px;
}

.app-date :deep(.q-date__calendar-days),
.app-date :deep(.q-date__calendar-weekdays) {
  flex-direction: row;
}

.app-date :deep(.q-date__view.q-date__calendar) {
  flex-direction: column;
}

.app-date :deep(.q-date__calendar-days) {
  flex-wrap: wrap;
}

.app-date :deep(.q-date__view) {
  min-height: unset;
  padding: unset;
}

.app-date :deep(.q-date__today) {
  box-shadow: unset;
}

.app-date :deep(.q-date__view.q-date__months),
.app-date :deep(.q-date__years-content) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin: auto;
}

.app-date :deep(.q-date__main) {
  min-height: 312px;
  min-width: 272px;
}

.app-date :deep(.q-date__content),
.app-date :deep(.q-date__years) {
  display: flex;
  height: auto;
}
</style>
