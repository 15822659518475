<script setup lang="ts">
import { reactive, watch } from 'vue';

interface Props {
  maxlength: number;
  placeholder?: string;
}

const editorText = defineModel<string>({ required: true });

const toolbarOperations = [
  ['bold', 'italic', 'underline', 'unordered', 'ordered'],
];

interface State {
  textLength: number;
}

const props = defineProps<Props>();
const state = reactive<State>({
  textLength: 0,
});

watch(
  () => editorText.value,
  (newValue: string | undefined, oldValue: string | undefined) => {
    if (newValue) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(newValue, 'text/html');
      const isTextLongerThanMax =
        (doc.body.textContent?.length || 0) > props.maxlength && oldValue;

      if (isTextLongerThanMax) {
        editorText.value = oldValue;
        return;
      }
      state.textLength = doc.body.textContent?.length || 0;
    }
  },
  { immediate: true },
);
</script>
<template>
  <div class="app-text-editor">
    <q-editor
      v-model="editorText"
      :toolbar="toolbarOperations"
      min-height="64px"
      max-height="64px"
      :placeholder="props.placeholder || undefined"
    />
    <span class="app-text-editor__counter"
      >{{ state.textLength }}/{{ props.maxlength }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.app-text-editor {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.app-text-editor__counter {
  align-self: flex-end;
  color: $gray-700;
  font-size: 12px;
  font-weight: 500;
}

:deep(.q-editor) {
  border: 1px solid $gray-400;
}

:deep(.q-editor):hover {
  border: 1px solid $blue-400;
}

:deep(.q-editor):focus-within {
  border: 1px solid $blue-400;
}
</style>
