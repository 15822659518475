import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10fede36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-button-toggle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTooltip = _resolveComponent("AppTooltip")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_AppField = _resolveComponent("AppField")!

  return (_openBlock(), _createBlock(_component_AppField, { error: _ctx.error }, {
    error: _withCtx(() => [
      _createVNode(_component_AppErrorMessage, { error: _ctx.error }, null, 8, ["error"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_btn_toggle, {
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          options: _ctx.options,
          ripple: false,
          "toggle-color": null,
          "text-color": null,
          "toggle-text-color": null,
          class: _normalizeClass([
          { 'app-button-toggle__toggle--error': _ctx.hasError },
          'app-button-toggle__toggle',
        ]),
          disable: _ctx.disabled,
          unelevated: "",
          "data-testid": "app-button-toggle"
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.options.filter((opt) => !!opt.slot), (option) => {
            return {
              name: option.slot,
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, option.slot, {}, () => [
                  (option.tooltip)
                    ? (_openBlock(), _createBlock(_component_AppTooltip, {
                        key: 0,
                        anchor: "top middle",
                        self: "bottom middle",
                        offset: [10, 10]
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option.tooltip), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ], true)
              ])
            }
          })
        ]), 1032, ["modelValue", "options", "class", "disable"])
      ]),
      _renderSlot(_ctx.$slots, "hintSpace", { class: "hint" }, undefined, true)
    ]),
    _: 3
  }, 8, ["error"]))
}