<template>
  <q-radio
    v-model="model"
    :class="{ 'app-radio': tabs }"
    v-bind="$props"
  ></q-radio>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppRadio',
  props: {
    modelValue: {
      type: [Object, String, Number],
      default: undefined,
    },
    tabs: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(newValue: object | string | number) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
});
</script>

<style scoped lang="scss">
.app-radio {
  height: 32px;
  padding: 6px 12px;
  background-color: $white;
  border: 1px solid $gray-100;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: $gray-800;
  text-transform: capitalize;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
}

.app-radio:first-child {
  border-radius: 4px 0px 0px 4px;
}

.app-radio:last-child {
  border-radius: 0px 4px 4px 0px;
}

.app-radio:not(:last-child) {
  border-right-width: 0;
}

.app-radio[aria-checked='true'] {
  background: $blue-50;
  border-color: $blue-500;
  border-right-width: 1px;
  color: $blue-500;
}

.app-radio[aria-checked='true'] + .app-radio {
  border-left-width: 0;
}

.app-radio :deep(.q-radio__inner) {
  display: none;
}
</style>
