<script setup lang="ts">
import { matInfo } from '@quasar/extras/material-icons';
import AppIcon from '../AppIcon/AppIcon.vue';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

interface Props {
  size?: string;
  tooltipMaxWidth?: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: '12px',
  tooltipMaxWidth: '340px',
});
</script>

<template>
  <AppIcon class="app-information" :name="matInfo" :size="props.size">
    <AppTooltip
      anchor="top middle"
      self="bottom middle"
      :offset="[10, 10]"
      :max-width="tooltipMaxWidth"
    >
      <slot />
    </AppTooltip>
  </AppIcon>
</template>

<style scoped lang="scss">
.app-information {
  color: $gray-400;
  align-self: center;
}
</style>
